define("@bolteu/ember-commons/i18n", ["exports", "@bolteu/ember-commons/utils/translations-macro"], function (_exports, _translationsMacro) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "t", {
    enumerable: true,
    get: function get() {
      return _translationsMacro.default;
    }
  });
});