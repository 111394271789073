define("@bolteu/ember-commons/utils/translations-macro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createTranslatedComputedProperty;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  // This is lifted from ember-i18n and modified -
  // https://github.com/jamesarosen/ember-i18n/blob/b83c1bf3556ea888faf7bc42b9d6619ad359a5cb/addon/utils/macro.js
  function createTranslatedComputedProperty(key) {
    var _Ember;

    var interpolations = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var dependencies = ['translations.locale'];
    dependencies = dependencies.concat(values(interpolations)); // We cast to "any" because spread operator + TS is not that well supported

    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(dependencies).concat([function () {
      var translations = this.translations;
      (false && !(translations !== undefined) && Ember.assert("Cannot translate ".concat(key, ". ").concat(this, " does not have an property \"translations: service()\"."), translations !== undefined));
      return translations.t(key, mapPropertiesByHash(this, interpolations));
    }]));
  }

  function values(interpolations) {
    return Object.keys(interpolations).map(function (key) {
      return interpolations[key];
    });
  }

  function mapPropertiesByHash(object, interpolations) {
    var result = {};
    Object.keys(interpolations).forEach(function (key) {
      result[key] = Ember.get(object, interpolations[key]);
    });
    return result;
  }
});