define("@bolteu/ember-commons/templates/components/select-picker/dropdown-content-inner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VBxq/z5H",
    "block": "{\"symbols\":[\"@dropdownOptionSelected\",\"&default\",\"@hasBS4\"],\"statements\":[[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"select-picker-inner-content\"],[14,\"data-test-select-picker\",\"options-list\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,1],null,[[\"item\",\"group\"],[[30,[36,0],[\"select-picker/dropdown-option\"],[[\"hasBS4\",\"dropdownOptionSelected\"],[[32,3],[32,1]]]],[30,[36,0],[\"select-picker/dropdown-optgroup\"],[[\"hasBS4\"],[[32,3]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"ul\"],[14,0,\"dropdown-menu inner select-picker-inner-content\"],[14,\"data-test-select-picker\",\"options-list\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,1],null,[[\"item\",\"group\"],[[30,[36,0],[\"select-picker/dropdown-option\"],[[\"dropdownOptionSelected\"],[[32,1]]]],[30,[36,0],[\"select-picker/dropdown-optgroup\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\"]}",
    "moduleName": "@bolteu/ember-commons/templates/components/select-picker/dropdown-content-inner.hbs"
  });

  _exports.default = _default;
});