define("@bolteu/ember-commons/templates/components/select-picker/dropdown-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wHjAjuSi",
    "block": "{\"symbols\":[\"@dropdownOptionSelected\",\"@hasBS4\",\"&default\"],\"statements\":[[6,[37,0],[[35,2]],[[\"defaultClass\"],[\"open select-picker-content\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dropdown-menu open show\"],[12],[2,\"\\n    \"],[18,3,[[30,[36,1],null,[[\"search\",\"options\"],[[30,[36,0],[\"select-picker/search-box\"],null],[30,[36,0],[\"select-picker/dropdown-content-inner\"],[[\"hasBS4\",\"dropdownOptionSelected\"],[[32,2],[32,1]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"dd\"]}",
    "moduleName": "@bolteu/ember-commons/templates/components/select-picker/dropdown-content.hbs"
  });

  _exports.default = _default;
});