define("@bolteu/ember-commons/helpers/is-eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEq = isEq;
  _exports.default = void 0;

  function isEq(params)
  /*, hash*/
  {
    return params[0] === params[1];
  }

  var _default = Ember.Helper.helper(isEq);

  _exports.default = _default;
});