define("@bolteu/ember-commons/templates/components/select-picker/dropdown-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bVzvz0Hp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"a\"],[24,\"tabindex\",\"0\"],[24,6,\"\"],[24,0,\"opt dropdown-item\"],[24,\"role\",\"option\"],[24,\"aria-disabled\",\"false\"],[24,\"aria-selected\",\"false\"],[4,[38,0],[[32,0,[\"scrollToView\"]]],null],[4,[38,1],[[32,0,[\"scrollToView\"]]],null],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text\"],[12],[18,1,null],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\"]}",
    "moduleName": "@bolteu/ember-commons/templates/components/select-picker/dropdown-option.hbs"
  });

  _exports.default = _default;
});