define("@bolteu/ember-commons/helpers/from-unix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fromUnix = fromUnix;
  _exports.default = void 0;

  function fromUnix(params)
  /*, hash*/
  {
    return Number(params[0]) * 1000;
  }

  var _default = Ember.Helper.helper(fromUnix);

  _exports.default = _default;
});