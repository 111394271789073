define("@bolteu/ember-commons/helpers/taxify-phone-area-code-i18n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taxifyPhoneAreaCodeI18n = taxifyPhoneAreaCodeI18n;
  _exports.default = void 0;

  /**
   * Override this helper in your application to localize country names
   * ember g taxify-phone-area-code-i18n
   *
   * @param params Array - first element is country code, second is country name
   * @return String
   */
  function taxifyPhoneAreaCodeI18n(params)
  /*, hash*/
  {
    return params[1];
  }

  var _default = Ember.Helper.helper(taxifyPhoneAreaCodeI18n);

  _exports.default = _default;
});