define("@bolteu/ember-commons/templates/components/select-picker/dropdown-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/B3t+V9w",
    "block": "{\"symbols\":[\"@value\",\"@placeholder\",\"&default\",\"@hasBS4\",\"@class\",\"@dd\"],\"statements\":[[6,[37,2],[[32,6]],[[\"defaultClass\"],[[30,[36,1],[\"btn dropdown-toggle \",[32,5],\" \",[30,[36,0],[[32,4],\"btn-light\",\"btn-default\"],null]],null]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"select-picker/selected-option\",[],[[],[]],[[\"default\"],[{\"statements\":[[18,3,null]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"select-picker/selected-option\",[],[[\"@value\",\"@placeholder\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"component\"]}",
    "moduleName": "@bolteu/ember-commons/templates/components/select-picker/dropdown-trigger.hbs"
  });

  _exports.default = _default;
});