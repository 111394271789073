define("@bolteu/ember-commons/templates/components/select-picker/native-content-inner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nne10/jq",
    "block": "{\"symbols\":[\"@addOption\",\"&default\"],\"statements\":[[18,2,[[30,[36,2],null,[[\"item\",\"group\"],[[30,[36,0],[\"select-picker/native-option\"],[[\"addOption\"],[[30,[36,1],[[32,0],[32,1]],null]]]],[30,[36,0],[\"select-picker/native-optgroup\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"action\",\"hash\"]}",
    "moduleName": "@bolteu/ember-commons/templates/components/select-picker/native-content-inner.hbs"
  });

  _exports.default = _default;
});