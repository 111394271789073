function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

(function () {
  'use strict';

  var createCache = Ember._createCache,
      getValue = Ember._cacheGetValue;

  function cached() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var target = args[0],
        key = args[1],
        descriptor = args[2]; // Error on `@cached()`, `@cached(...args)`, and `@cached propName = value;`

    (false && !(target !== undefined) && Ember.assert('You attempted to use @cached(), which is not necessary nor supported. Remove the parentheses and you will be good to go!', target !== undefined));
    (false && !(_typeof(target) === 'object' && typeof key === 'string' && _typeof(descriptor) === 'object' && args.length === 3) && Ember.assert("You attempted to use @cached on with ".concat(args.length > 1 ? 'arguments' : 'an argument', " ( @cached(").concat(args.map(function (d) {
      return "'".concat(d, "'");
    }).join(', '), "), which is not supported. Dependencies are automatically tracked, so you can just use ", '`@cached`'), _typeof(target) === 'object' && typeof key === 'string' && _typeof(descriptor) === 'object' && args.length === 3));
    (false && !(typeof descriptor.get == 'function') && Ember.assert("The @cached decorator must be applied to getters. '".concat(key, "' is not a getter."), typeof descriptor.get == 'function'));
    var caches = new WeakMap();
    var getter = descriptor.get;

    descriptor.get = function () {
      if (!caches.has(this)) caches.set(this, createCache(getter.bind(this)));
      return getValue(caches.get(this));
    };
  }

  Ember._cached = cached;
})();