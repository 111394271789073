define("@bolteu/ember-commons/templates/components/select-picker/selected-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KMDq8c9Q",
    "block": "{\"symbols\":[\"@placeholder\",\"@value\",\"&default\"],\"statements\":[[10,\"span\"],[15,0,[30,[36,0],[[32,1],[30,[36,1],[[27,[32,3]],[30,[36,1],[[32,2],\"text-muted\"],null]],null]],null]],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"filter-option pull-left\"],[14,\"data-test-select-picker\",\"trigger-content\"],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[2,\"   \\n\"],[2,\"  \"],[10,\"span\"],[14,0,\"bs-caret\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"caret\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"unless\"]}",
    "moduleName": "@bolteu/ember-commons/templates/components/select-picker/selected-option.hbs"
  });

  _exports.default = _default;
});