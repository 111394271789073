define("@bolteu/ember-commons/templates/components/select-picker/search-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KOXaGl97",
    "block": "{\"symbols\":[],\"statements\":[[11,\"input\"],[24,0,\"form-control\"],[24,\"autocomplete\",\"off\"],[24,\"role\",\"textbox\"],[16,\"oninput\",[30,[36,0],[[32,0],\"enterSearchTerm\"],null]],[16,\"onkeydown\",[30,[36,0],[[32,0],\"userPressedKey\"],null]],[24,4,\"text\"],[4,[38,1],[[32,0,[\"focusElement\"]]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\"]}",
    "moduleName": "@bolteu/ember-commons/templates/components/select-picker/search-box.hbs"
  });

  _exports.default = _default;
});