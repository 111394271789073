define("@bolteu/ember-commons/templates/components/select-picker/dropdown-optgroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CEf/BoZ/",
    "block": "{\"symbols\":[\"@label\",\"&default\",\"@hasBS4\"],\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dropdown-header\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text\"],[12],[1,[32,1]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[18,2,null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown-divider\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"li\"],[14,0,\"dropdown-header\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text\"],[12],[1,[32,1]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[18,2,null],[2,\"\\n  \"],[10,\"li\"],[14,0,\"divider\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "@bolteu/ember-commons/templates/components/select-picker/dropdown-optgroup.hbs"
  });

  _exports.default = _default;
});