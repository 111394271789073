define("@bolteu/ember-commons/mixins/reset-scroll-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // eslint-disable-line ember/no-new-mixins
    activate: function activate() {
      this._super.apply(this, arguments);

      window.scrollTo(0, 0);
    }
  });

  _exports.default = _default;
});