define('ember-data/-private', ['exports', '@ember-data/store', 'ember-data/version', '@ember-data/model/-private', '@ember-data/store/-private', '@ember-data/record-data/-private'], function (exports, store, VERSION, Private, Private$1, Private$2) { 'use strict';

  store = store && Object.prototype.hasOwnProperty.call(store, 'default') ? store['default'] : store;
  VERSION = VERSION && Object.prototype.hasOwnProperty.call(VERSION, 'default') ? VERSION['default'] : VERSION;

  /**
   * @property VERSION
   * @public
   * @static
   * @for @ember-data
   */

  var DS = Ember.Namespace.create({
    VERSION: VERSION,
    name: 'DS'
  });

  if (Ember.libraries) {
    Ember.libraries.registerCoreLibrary('Ember Data', VERSION);
  }

  function featureIsEnabled() {
    return Ember.FEATURES.isEnabled.apply(void 0, arguments);
  }

  exports.Store = store;
  Object.defineProperty(exports, 'Errors', {
    enumerable: true,
    get: function () {
      return Private.Errors;
    }
  });
  Object.defineProperty(exports, 'AdapterPopulatedRecordArray', {
    enumerable: true,
    get: function () {
      return Private$1.AdapterPopulatedRecordArray;
    }
  });
  Object.defineProperty(exports, 'InternalModel', {
    enumerable: true,
    get: function () {
      return Private$1.InternalModel;
    }
  });
  Object.defineProperty(exports, 'ManyArray', {
    enumerable: true,
    get: function () {
      return Private$1.ManyArray;
    }
  });
  Object.defineProperty(exports, 'PromiseArray', {
    enumerable: true,
    get: function () {
      return Private$1.PromiseArray;
    }
  });
  Object.defineProperty(exports, 'PromiseManyArray', {
    enumerable: true,
    get: function () {
      return Private$1.PromiseManyArray;
    }
  });
  Object.defineProperty(exports, 'PromiseObject', {
    enumerable: true,
    get: function () {
      return Private$1.PromiseObject;
    }
  });
  Object.defineProperty(exports, 'RecordArray', {
    enumerable: true,
    get: function () {
      return Private$1.RecordArray;
    }
  });
  Object.defineProperty(exports, 'RecordArrayManager', {
    enumerable: true,
    get: function () {
      return Private$1.RecordArrayManager;
    }
  });
  Object.defineProperty(exports, 'RootState', {
    enumerable: true,
    get: function () {
      return Private$1.RootState;
    }
  });
  Object.defineProperty(exports, 'Snapshot', {
    enumerable: true,
    get: function () {
      return Private$1.Snapshot;
    }
  });
  Object.defineProperty(exports, 'SnapshotRecordArray', {
    enumerable: true,
    get: function () {
      return Private$1.SnapshotRecordArray;
    }
  });
  Object.defineProperty(exports, 'coerceId', {
    enumerable: true,
    get: function () {
      return Private$1.coerceId;
    }
  });
  Object.defineProperty(exports, 'normalizeModelName', {
    enumerable: true,
    get: function () {
      return Private$1.normalizeModelName;
    }
  });
  Object.defineProperty(exports, 'RecordData', {
    enumerable: true,
    get: function () {
      return Private$2.RecordData;
    }
  });
  Object.defineProperty(exports, 'Relationship', {
    enumerable: true,
    get: function () {
      return Private$2.Relationship;
    }
  });
  exports.DS = DS;
  exports.isEnabled = featureIsEnabled;

  Object.defineProperty(exports, '__esModule', { value: true });

});
