define("@bolteu/ember-commons/templates/components/select-picker/native-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "smDuzwID",
    "block": "{\"symbols\":[\"@value\",\"@placeholder\",\"&default\",\"@hasBS4\",\"@class\"],\"statements\":[[10,\"button\"],[15,0,[30,[36,1],[\"btn dropdown-toggle \",[32,5],\" \",[30,[36,0],[[32,4],\"btn-light w-100\",\"btn-default\"],null]],null]],[14,\"role\",\"button\"],[14,4,\"button\"],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"select-picker/selected-option\",[],[[],[]],[[\"default\"],[{\"statements\":[[18,3,null]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"select-picker/selected-option\",[],[[\"@value\",\"@placeholder\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\"]}",
    "moduleName": "@bolteu/ember-commons/templates/components/select-picker/native-trigger.hbs"
  });

  _exports.default = _default;
});