define("@bolteu/ember-commons/utils/download-blob", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = downloadBlob;
  var stack = [];

  function downloadBlob(blob, filename) {
    var urlPreviouslyOpened = stack.pop(); // lazily revoke previous url object

    if (urlPreviouslyOpened) {
      URL.revokeObjectURL(urlPreviouslyOpened);
    }

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var objectURL = URL.createObjectURL(blob);
      var elem = window.document.createElement('a');
      elem.href = objectURL;
      elem.download = filename;
      elem.style.display = 'none';
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
      stack.push(objectURL);
    }
  }
});