define("@bolteu/ember-commons/utils/is-local-storage-available", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isLocalStorageAvailable;
  var TEST_KEY = 'testing_if_available';

  function isLocalStorageAvailable() {
    var available = false;

    try {
      var today = new Date().toString();
      var storage = window.localStorage;
      storage.setItem(TEST_KEY, today);
      var readValue = storage.getItem(TEST_KEY);
      storage.removeItem(TEST_KEY);
      available = readValue === today;
    } catch (e) {// no-op
    }

    return available;
  }
});