define("@bolteu/ember-commons/templates/components/select-picker/native-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TAD76bLY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"select\"],[14,0,\"form-control mobile-device\"],[15,\"onchange\",[30,[36,0],[[32,0],\"optionSelected\"],null]],[12],[2,\"\\n  \"],[18,1,[[30,[36,2],null,[[\"options\"],[[30,[36,1],[\"select-picker/native-content-inner\"],[[\"addOption\"],[[30,[36,0],[[32,0],\"addOption\"],null]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "moduleName": "@bolteu/ember-commons/templates/components/select-picker/native-content.hbs"
  });

  _exports.default = _default;
});